import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import "../styles/bg.css";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            adItem: {
                success: false,
                adId:'',
                eventId:'',
                adItem1: {
                    adItems:[]
                },
                adImage:'',
                adHeight:'',
                adUrl:'',
                adAImage:'',
                adAUrl:'',
                adBImage:'',
                adBUrl:'',
                adCImage:'',
                adCUrl:''
            }
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: ''
        };
        let { eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        console.log(this.props.location.search);
        console.log("123");
        console.log(eventId);
        // rejectIfAnyNull(this.props.session, this.props.session);
    }

    async componentDidMount(){
        console.log(this.state.adItem.success);
        if ((this.state.gameId === "8595e5c6-9282-4197-a54e-dd192ec13409")
            // || (this.state.gameId === "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
            || (this.state.gameId === "d2fd2589-0e94-4323-ac21-ba52b63d7149")
            || (this.state.gameId === "72153dd5-1681-4f73-a581-c0c4e1ffe21a")
            || (this.state.gameId === "f9914787-12db-423d-9695-f1c95432d56a")
            || (this.state.gameId === "97faa45e-795e-4590-bb8d-86a2f8473739")
            || (this.state.gameId === "3c7538c3-2478-4e2a-af82-f49445e96c6f")
            || (this.state.gameId === "98b345ba-79b6-4209-88ff-87ba865ddf54")
            || (this.state.gameId === "0b78cded-f7ef-4ba1-a32b-d22c1e001a97")
            || (this.state.gameId === "0ca86e6d-d223-4b76-98af-967a52b09323")
        ) {
            // || (this.state.gameId === "d074e9aa-ab68-447f-9c69-ac6f04191cf3")
            const wigth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            let wh = wigth;
            if (height < wh) wh = height;
            if (wh < 375) {
                this.state.adItem.adHeight = '249px';
            } else {
                this.state.adItem.adHeight = '289px';
            }
        }
    }

    render() {
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={this.state.divStyle}>

                            <div style={{width:'289px',height:this.state.adItem.adHeight,borderRadius:'10px 10px 0px 0px'}}
                            >
                                <iframe src="" style={{width:"100%", height:"100%"}} frameBorder="no" border="0"></iframe>
                            </div>
                            <div style={{height:'41px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                                paddingTop:'14px',border:'1px solid #D6D6D6',
                                borderRadius:'0px 0px 10px 10px',
                                opacity:'1'}}
                                 onClick={() => {
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                 }}
                            >
                                <span style={{color:'#FF7700'}}>知道了~</span>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});